<template>
    <div>
        <!-- pc 端 -->
        <div class="screenWidth">
            <currentLocation :sibItem="sibItem"></currentLocation>
            <div class="Producone_box">
                <div class="Producone">
                    <div class="swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item, index) in info.goods_images_list" :key="index">
                                <img :src="item" alt="">
                            </div>
                            <!-- 更多轮播项 -->
                        </div>
                        <!-- 如果需要分页器 -->
                        <div  class="swiper-pagination"></div>
                    </div>
                    <div class="Producone_left">
                        <div @mouseleave="tabhuachu" class="Producone_left_img" @mousemove="mousemove">
                            <div class="small" ref="small">
                                <img :src="info.goods_images_list[imgindex]" alt="">
                                <div v-if="isShow" class="mask" :style="{ 'left': left + 'px', 'top': top + 'px', }"
                                    ref="mask"></div>
                            </div>
                            <div v-if="isShow" class="big" ref="big">
                                <img ref="bigimg" :src="info.goods_images_list[imgindex]" alt=""
                                    :style="{ 'left': imgX + 'px', 'top': imgY + 'px', }" />
                            </div>
                        </div>
                        <div class="Producone_left_list">
                            <vue-scroll ref="scrollNoticeDetails">
                                <div class="img_boxs">
                                    <img :class="{ 'imgActive': imgindex == index }" @click="imgClick(index)" v-for="(item, index) in info.goods_images_list
" :key="index" :src="item" alt="">
                                </div>
                            </vue-scroll>
                        </div>
                    </div>
                    <div class="Producone_right">
                        <div class="title"> {{ info.name }}</div>
                        <div class="list">
                            <div class="name" v-html="info.descript"></div>
                            <!-- <div class="name"> {{ info.descript }} </div> -->
                            <!-- <div class="guige" v-for="(item, index) in info.canshu_configjson" :key="index"><span>{{
                                item.name }}</span>
                                <span>{{ item.gender }}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- 详情 -->
            <div class="ProductDescription" v-if="info.image && info.content">
                <div class="tablist">
                    <div :class="{ 'active': tabindex == 1 }">Product Description</div>
                </div>
                <div class="zhanwei"></div>
                <!-- 平板显示 -->
                <div class="tablist_pb">
                    <div>Product Description</div>
                </div>
                <div class="zhanwei_pb"></div>
                <img v-if="tabindex == 1" class="Mastermap" :src="info.image" alt="">
                <div class="Rich_text" v-if="tabindex == 1" v-html="info.content"></div>
            </div>
        </div>
        <div class="kong" v-if="!info">
            <img src="../assets/image/kong.png" alt="">
            <div>No Data</div>
        </div>
    </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：产品详情
 * @author: 刘义博
 * @version  V1.0 
 * @datecreate: 2024-07-19 10:13
 */

import 'swiper/swiper-bundle.css';
import Swiper from 'swiper/swiper-bundle'
export default {
    name: "swiper",
    data() {
        return {
            //主图
            mackIndex: 0,//蒙层下标
            imgindex: 0,
            isShow: false,
            top: '',
            left: '',
            imgX: '',
            imgY: '',
            detailId: '',
            info: '',//产品详情
            tabindex: 1,//teb切换
            sibItem: [
                {
                    title: "Home",
                    path: "/",
                },
                {
                    title: "product",
                    path: "/product",
                },
                {
                    title: "productDetails",
                    path: "",
                },
            ],

        }
    },
    mounted() {
        this.detailId = this.$route.query.id
        this.getdetail()
    },
    watch: {

    },
    methods: {
        tabclick(index) {
            this.tabindex = index
        },
        /**
        * 获取详情数据
        * 刘义博
        * 2024-7-20
        */
        getdetail() {
            this.$request({
                url: "/Index/goodsInfo",
                data: {
                    id: this.detailId
                }
            }).then((res) => {
                console.log("产品详情", res);
                this.info = res.data.goods_info
                this.info.content = this.info.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
                this.$nextTick(() => {
                    var mySwiper = new Swiper('.swiper', { //产品列表轮播
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true, // 是否可以点击切换
                        },
                        slidesPerGroup: 1,
                    })
                })
            });
        },
        /**
         * 切换主图
         * 刘义博
         * 2024-7-19
         */
        imgClick(index) {
            this.imgindex = index
        },
        /**
         * 鼠标移出图片
         * 刘义博
         * 2024-7-19
         */
        tabhuachu() {
            this.isShow = false
            this.top = ''
            this.left = ''
            this.imgX = ''
            this.imgY = ''
        },
        /**
         * 鼠标移入图片
         * 刘义博
         * 2024-7-19
         */
        mousemove(e) {
            this.isShow = true
            let small = this.$refs.small
            let mask = this.$refs.mask
            let big = this.$refs.big
            let bigimg = this.$refs.bigimg
            let maskX = e.pageX - small.offsetLeft
            let maskY = e.pageY - small.offsetTop
            maskX = maskX - mask.offsetWidth / 2;
            maskY = maskY - mask.offsetHeight / 2;
            maskX = maskX < 0 ? 0 : maskX;
            maskY = maskY < 0 ? 0 : maskY;
            maskX = maskX > small.offsetWidth - mask.offsetWidth ? small.offsetWidth - mask.offsetWidth : maskX;
            maskY = maskY > small.offsetHeight - mask.offsetHeight ? small.offsetHeight - mask.offsetHeight : maskY;
            let bigImgX = maskX * (big.offsetWidth - bigimg.offsetWidth) / (small.offsetWidth - mask.offsetWidth);
            let bigImgY = maskY * (big.offsetHeight - bigimg.offsetHeight) / (small.offsetHeight - mask.offsetHeight)
            this.left = maskX
            this.top = maskY
            this.imgX = bigImgX
            this.imgY = bigImgY
        },
    },
    computed: {},
    filters: {},
    components: {}
}
</script>
<style scoped lang="less">
// 高版本版本swiper
::v-deep .swiper {
    width: 100%;
    padding-bottom: 250px;

    .swiper-slide {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 50%;
        }
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        width: 45px !important;
        height: 45px !important;
        background: #9EACA7 !important;
        border-radius: 50% !important;

    }
}

.swiper {
    display: none;
}

@media (min-width: 1px) and (max-width: 767px) {
    .swiper {
        display: block !important;
    }

    .Recommended {
        margin: 50px 0;
        font-size: 0.8em !important;
    }

    .Greenheading {
        font-size: 80px !important;
        padding: 10px 40px !important;
    }

    .ProductDescription {
        padding: 0 !important;
        border: 0 !important;
        margin-top: 200px !important;
    }

    .Producone_left_pb {
        width: 100%;
        
    }

    .tablist,
    .zhanwei {
        display: none !important;
    }

    .zhanwei_pb {
        display: block !important;
        height: 200px;

        div {
            text-decoration: underline;
            
        }
    }

    .tablist_pb {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #BA0003;
        display: block !important;

        div {
            width: 100%;
            padding: 40px 60px;
            font-size: 70px;
            font-weight: bold;
            box-sizing: border-box;
            color: #fff;
        }

        .active {
            background: #FC7B0B;
        }
    }

    .Mastermap {
        object-fit: contain !important;
        width: 100% !important;
        min-height: 1000px !important;
        margin: 150px 0 !important;
    }

    .Productperformance {
        li {
            line-height: 1em !important;
        }

        .block_box {
            width: 23px !important;
            height: 23px !important;
            margin-right: 50px !important;
        }

        .introduce {
            font-size: 70px !important;
        }
    }

    .Performance {
        .title {
            width: 300px !important;
            font-size: 70px !important;
            font-weight: 400 !important;
        }

        .title_canshu {
            width: 580px !important;
            font-size: 70px !important;
        }
    }

    .specification {

        div,
        span {
            font-size: 70px !important;
            line-height: 2em;
        }
    }

    .Producone {
        display: flex;
        flex-direction: column;
    }

    .Producone_left {
        display: none !important;
    }

    .Producone_right {
        width: 100% !important;
        margin-top: 150px;

        .title {
            font-size: 100px !important;

        }

        .list .name {
            font-size: 60px !important;
            line-height: 150px !important;
            margin-top: 100px;
        }

        .guige {
            width: 100% !important;
            font-size: 65px !important;
            line-height: 1.5em !important;

            span:nth-child(1) {
                min-width: 20% !important;
            }

            span {
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .swiper {
        display: block !important;
    }

    // .swiper-container {
    //     display: block !important;
    // }

    .Recommended {
        margin: 50px 0;
        font-size: 0.8em !important;
    }

    .Greenheading {
        font-size: 0.8em !important;
    }

    .ProductDescription {
        padding: 0 !important;
        border: 0 !important;
    }

    .Producone_left_pb {
        width: 100%;

    }

    .tablist,
    .zhanwei {
        display: none !important;
    }

    .zhanwei_pb {
        display: block !important;
        height: 200px;
    }

    .tablist_pb {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #BA0003;
        display: block !important;

        div {
            width: 100%;
            height: 90px;
            padding: 0 20px;
            font-size: 30px;
            line-height: 90px;
            font-weight: bold;
            box-sizing: border-box;
            color: #fff;
        }

        .active {
            background: #FC7B0B;
        }
    }

    .Mastermap {
        width: 100% !important;
        max-height: 800px !important;
        object-fit: contain !important;
    }

    .Productperformance {
        li {
            line-height: 1.4em !important;
        }

        .block_box {
            width: 11px !important;
            height: 11px !important;
        }

        .introduce {
            font-size: 0.9em !important;
        }
    }

    .Performance {
        .title {
            width: 350px !important;
            font-size: 0.9em !important;
            font-weight: 400 !important;
        }

        .title_canshu {
            width: 470px !important;
            font-size: 0.9em !important;
        }
    }

    .specification {

        div,
        span {
            font-size: 0.9em !important;
            line-height: 2em;
        }
    }

    .Producone {
        display: flex;
        flex-direction: column;
    }

    .Producone_left {
        display: none !important;
    }

    .Producone_right {
        margin-top: 150px;
        width: 100% !important;

        .title {
            font-size: 1em !important;
        }

        .list .name {
            font-size: 0.8em !important;
        }

        .list .guige {
            font-size: 0.6em !important;
            margin-top: 1em !important;

            span:nth-child(1) {
                min-width: 10% !important;
            }

            span {
                margin-right: 5em !important;
            }
        }
    }
}

.screenWidth {
    padding-bottom: 100px;
}

//pc 样式
.Greenheading {
    display: inline-block;
    background: rgba(186, 0, 3, 1);
    color: #fff;
    font-size: 23px;
    padding: 2px 10px;
    font-weight: bold;
}

.Producone_box {
    background: #fff;

    .Producone {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .Producone_left {
            display: flex;
            height: 500px;

            .Producone_left_list {
                height: 73%;
                width: 110px;
                margin-left: 22px;
                overflow-y: scroll;
                margin-top: 2px;

                .img_boxs {
                    width: 100%;
                    height: 85%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    cursor: pointer;
                }


                img {
                    width: 100px;
                    height: 100px;
                    border: 1px solid #EEEEEE;
                    padding: 10px;
                    margin-bottom: 31px;
                    box-sizing: border-box;
                    object-fit: contain !important;
                }

                .imgActive {
                    border: 1px solid rgba(186, 0, 3, .5);
                }
            }

            ::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            .Producone_left_img {
                width: 500px;
                height: 500px;
                background: #FFFFFF;
                border: 2px solid #EEEEEE;

                //    position: relative;
                div,
                img {
                    transition: all 0s !important;
                }

                .small {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain !important;
                    }
                }

                .mask {
                    width: 200px;
                    height: 200px;
                    background: rgba(186, 0, 3, 0.2);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    cursor: move;
                }

                .big {
                    width: 520px;
                    height: 498px;
                    position: absolute;
                    top: 169px;
                    left: 885px;
                    overflow: hidden;
                    border: 1px solid #eee;
                    background: #fff;
                    z-index: 10;
                }

                .big img {
                    position: absolute;
                    width: 1000px;
                    height: 1000px;
                    left: 0;
                    top: 0;
                    object-fit: contain !important;
                }
            }

        }

        .Producone_right {
            width: 45%;

            .numbers {
                font-size: 19px;
                color: #222222;
                margin-top: 20px;
            }

            .title {
                font-weight: bold;
                font-size: 28px;
                color: rgba(186, 0, 3, 1);
                text-align: center;
            }

            .list {
                width: 100%;
                margin-top: 20px;

                .name {
                    font-size: 16px;
                    line-height: 1.7em;
                }

                .guige {
                    font-weight: 400;
                    font-size: 15px;
                    color: #222;
                    margin-top: 10px;
                    display: flex;
                    align-items: flex-start;

                    span:nth-child(1) {
                        min-width: 20%;
                    }

                    span:nth-child(2) {
                        width: auto;
                    }

                    span {
                        display: block;
                    }
                }
            }
        }
    }
}

.ProductDescription {
    width: 100%;
    border: 1px solid #dcdcdc;
    margin-top: 30px;
    padding: 0 10px 10px 10px;
    position: relative;

    ::v-deep .Rich_text p{
        line-height: 35px !important;
    }

    .tablist_pb,
    .zhanwei_pb {
        display: none;
    }

    .tablist {
        width: 100%;
        height: 50px;
        background: #BA0003;
        color: #fff;
        line-height: 50px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: default;

        div {
            display: inline-block;
            padding: 0 20px;
            font-size: 20px;
            font-weight: bold;
        }

        .active {
            background: #FC7B0B;
        }
    }

    .zhanwei {
        height: 60px;
        width: 100%;
    }

    .Mastermap {
        width: 70%;
        max-height:500px;
        margin: 0 auto;
        object-fit: contain !important;
    }

    .Recommended {
        margin: 20px 0;
        font-weight: bold;
        font-size: 22px;
        text-decoration: underline;
        text-decoration-color: #222222;
        /* 设置下划线颜色为红色 */
        cursor: pointer;
    }

    .Recommended:hover {
        font-weight: 500;
        color: #BA0003;
        text-decoration: none;
    }
}
</style>